import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import CharacterTraveling from '../../media/character_traveling.json'

const Lottie = React.lazy(() => import("lottie-react"));

const countries = [
    { name: 'Monaco', code: 'MC', flag: '&#x1F1F2;&#x1F1E8;' },
    { name: 'Malaysia', code: 'MY', flag: '&#x1F1F2;&#x1F1FE;' },
    { name: 'Poland', code: 'PL', flag: '&#x1F1F5;&#x1F1F1;' },
    { name: 'USA', code: 'US', flag: '&#x1F1FA;&#x1F1F8;' },
    { name: 'Belgium', code: 'BE', flag: '&#x1F1E7;&#x1F1EA;' },
    { name: 'Cambodia', code: 'KH', flag: '&#x1F1F0;&#x1F1ED;' },
    { name: 'Spain', code: 'ES', flag: '&#x1F1EA;&#x1F1F8;' },
    { name: 'France', code: 'FR', flag: '&#x1F1EB;&#x1F1F7;' },
    { name: 'Vatican', code: 'VA', flag: '&#x1F1FB;&#x1F1E6;' },
    { name: 'Northern Ireland', code: 'GB-NIR', flag: '&#x1F1EC;&#x1F1E7;' },
    { name: 'Singapore', code: 'SG', flag: '&#x1F1F8;&#x1F1EC;' },
    { name: 'Portugal', code: 'PT', flag: '&#x1F1F5;&#x1F1F9;' },
    { name: 'Mexico', code: 'MX', flag: '&#x1F1F2;&#x1F1FD;' },
    { name: 'Germany', code: 'DE', flag: '&#x1F1E9;&#x1F1EA;' },
    { name: 'Italy', code: 'IT', flag: '&#x1F1EE;&#x1F1F9;' },
    { name: 'Amsterdam', code: 'NL', flag: '&#x1F1F3;&#x1F1F1;' },
    { name: 'Vietnam', code: 'VN', flag: '&#x1F1FB;&#x1F1F3;' },
];

// eslint-disable-next-line no-unused-vars
const renderCountriesList = () => {
    const columns = 4;
    const rows = Math.ceil(countries.length / columns);

    return (
        <Grid
            container
            spacing={3}
            justifyContent="center"
        // alignItems="center"
        >
            {[...Array(columns)].map((col, index) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {[...Array(rows)].map((row, rowIndex) => {
                            const countryIndex = rowIndex * columns + index;
                            const country = countries[countryIndex];

                            return country ? (
                                <div
                                    key={country.code}
                                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: country.flag }} />
                                    <Typography>{country.name}</Typography>
                                </div>
                            ) : null;
                        })}
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};


export default function Types() {
    return (

        <Box className='aboutMeContainer' sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <svg viewBox="0 0 2225 1777" fill="none" xmlns="http://www.w3.org/2000/svg" className="svgBlob">
                <path d="M1624 1582.5c0 107.42-87.08 194.5-194.5 194.5s-194.5-87.08-194.5-194.5 87.08-194.5 194.5-194.5 194.5 87.08 194.5 194.5zM1731 1727c0 24.85-18.8 45-42 45s-42-20.15-42-45 18.8-45 42-45 42 20.15 42 45z" fill="currentColor">
                </path><path fill-rule="evenodd" clip-rule="evenodd" d="M2219.9 1100.67c36.65 167.01-131.88 409-377.65 338.33-245.77-70.67-295.96-105.18-376.25-106.94-261.65-5.73-407.34 223.33-666.211 251.1-244.843 26.26-507.05-40.56-729.177-426.94C-86.171 883.49 48.823 563.839 203.42 399.887c206.505-219.001 319.231-85.645 602.781-220.921 176.712-84.3 409.709-273.364 724.859-121.024 234.16 113.189 264.86 431.394 334.56 593.353 87.2 294.49 306.94 233.704 354.28 449.375z" fill="currentColor">
                </path>
            </svg>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <div className='container'>
                    <Grid xs={12} md={12}>
                        <Typography gutterBottom textAlign='center' className='aboutMe_Heading'>
                            / about me
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Typography variant="h6" gutterBottom className='content'>
                            I've been working in IT for 6 years now... and I like to think that I'm not the stereotypical nerd!🤓
                            I'm just as good at working with people as I am at technology.
                            {/* I want to create things that make a difference in the world, leaving it in a better place than when I came into it! */}
                            {/* I like creating software that makes the world a better place. */}

                        </Typography>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Typography variant="h6" gutterBottom className='content'>
                            {/* Over the years I’ve been very lucky to work with some cool tech, but my favourite has to be the serverless tech.
                            It’s fantastic how cloud computing and serverless technology have made (the previously exclusive) enterprise-grade technology accessible to everyone!
                            I think that it enables more innovation in the world. */}
                            Throughout my career, I've had the privilege of working with some impressive technology,
                            but none has captured my interest quite like serverless tech.
                            The way cloud computing and serverless technology have made enterprise-grade technology available to everyone is nothing short of amazing.
                            This has enabled a world of innovation and opened up opportunities for countless people.

                        </Typography>
                    </Grid>
                    <Grid container justifyContent={'center'} alignItems='center'>
                        <Grid item xs={12} md={4} className='content travelLottie'>
                            <Lottie className='lottieTraveling' animationData={CharacterTraveling} loop={true} />
                        </Grid>
                        <Grid item xs={12} md={8} >
                            <Typography variant="h6" gutterBottom className='content '>
                                {/* Aside from technology, I love to travel and experience new things.
                                Seeing the world makes life more interesting and I try to make time to travel as much as I can!
                                My favourite part of travelling has to be eating <b>ALL</b> the foods! */}
                                Apart from being a tech enthusiast, I also love to travel and explore new things.
                                I believe that seeing different parts of the world can make life more interesting and fulfilling, so I try to make time to travel as much as possible.
                                The best part of traveling has to be trying out all the local foods!
                                It's a fantastic way to learn about different cultures and experience something new.
                            </Typography>

                        </Grid>
                    </Grid>
                    {/* {renderCountriesList()} */}
                    <Grid container>

                        <Grid item xs={12} md={12}>
                            <Typography variant="h6" gutterBottom className='content'>
                                {/* I'm also trying to learn a little Spanish in my spare time but I’m really bad with languages,so it’s a work in progress… */}
                                {/* Going to live gigs is always a good time for me!🤘
                                I've been so lucky to see amazing bands and artists like Foo Fighters, Muse, Green Day, Macklemore, Eminem, System of a Down, Coldplay and so many more. */}
                                Live gigs is another one of my favourites things to do. I can never say no to a good mosh pit! 🤘
                                I've been incredibly fortunate to see some amazing bands and artists like
                                Foo Fighters, Muse, Green Day, Macklemore, Eminem, System of a Down, Coldplay, and many others.
                            </Typography>

                        </Grid>
                        {/* <Grid item xs={12} md={4} className='content travelLottie'>
                            <Lottie animationData={TravelFinal} loop={true} />
                        </Grid> */}
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Typography variant="h6" gutterBottom className='content'>
                            {/* Gaming online with my friends is also something I do regularly.
                            Right now we're playing alot of Rocket League.
                            It's a great way to stay connected with people you care about while escaping the pressures of everyday life. */}
                            I love to game online with my friends, it's become a regular thing for me. Right now, we're hooked on Rocket League!
                            It's a fantastic way to stay in touch with people you care about, and forget about the stresses of everyday life for a while.
                        </Typography>
                    </Grid>
                </div>
            </Grid>

        </Box>
    );
}