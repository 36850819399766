import { React, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Lottie from "lottie-react";
import HeaderTextLottieAnimation from '../../media/header_text_v1.json'

export default function Header() {
  const [playAnimation, setPlayAnimation] = useState(false);

  return (
    <Box className='headerContainer' sx={{ flexGrow: 1 }}>

      <AppBar className='headerAppBar' position="static" color='transparent'>
        <Toolbar sx={{ paddingLeft: '0' }}>
          <Typography
            className='title'
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, textAlign: 'left', margin: 0, boxShadow: 0 }}>
            <a href={`/`}>
              <Lottie
                className='headerLottie'
                animationData={HeaderTextLottieAnimation}
                loop={!playAnimation}
                autoplay={playAnimation}
                onMouseEnter={() => setPlayAnimation(true)}
                onMouseLeave={() => setPlayAnimation(false)}
              />
            </a>

          </Typography>
          {/* <a href={`/contact`}>
            <Button
              className='contactButton'
              endIcon={<SendIcon size='large' className='contactButtonIcon' />}
              size='large'>
              Contact</Button>
          </a> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

// import lottie from "lottie-web";

// useEffect(() => {
//   lottie.loadAnimation({
//     container: container.current,
//     renderer: "svg",
//     loop: true,
//     autoplay: false,
//     animationData: require("./office.json")
//   });

//   return () => {
//     lottie.destroy();
//   };
// }, []);

// return (
//   <div className="App">
//     <h1>React Lottie Demo</h1>
//     <div
//       ref={container}
//       onMouseEnter={() => lottie.play()}
//       onMouseLeave={() => lottie.pause()}
//     />
//   </div>
// );




// https://lottiereact.com/hooks/useLottieInteractivity
// import { useLottie, useLottieInteractivity } from "lottie-react";
// import robotAnimation from "./robotAnimation.json";

// const style = {
//   height: 300,
//   border: 3,
//   borderStyle: "solid",
//   borderRadius: 7,
// };

// const options = {
//   animationData: robotAnimation,
// };

// const PlaySegmentsOnHover = () => {
//   const lottieObj = useLottie(options, style);
//   const Animation = useLottieInteractivity({
//     lottieObj,
//     mode: "cursor",
//     actions: [
//       {
//         position: { x: [0, 1], y: [0, 1] },
//         type: "loop",
//         frames: [45, 60],
//       },
//       {
//         position: { x: -1, y: -1 },
//         type: "stop",
//         frames: [45],
//       },
//     ],
//   });

//   return Animation;
// };

// export default PlaySegmentsOnHover;
