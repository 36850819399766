import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const renderSocialIcons = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={3}>
      <Grid className='iconHover'>
        <a
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/danjboyle/"
          target="_blank"
        >
          <LinkedInIcon
            label="Linkedin"
            fontSize='medium'
          />
        </a>
      </Grid>

      <Grid className='iconHover'>
        <a
          rel="noopener noreferrer"
          href="https://github.com/SuperHapyFunTime"
          target="_blank"
        >
          <GitHubIcon
            label="Github"
            fontSize='medium'
          />
        </a>
      </Grid>
    </Grid>
  );
}


export default function Footer() {
  return (
    <Box
      className='footerContainer'
      sx={{
        justifyContent: 'center'
      }}>

      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={4}>
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'center',
              margin: 0,
              boxShadow: 0
            }}
          >
            &#169; {new Date().getFullYear()} danboyle.dev
          </Typography>
        </Grid>
        <Grid
          item
          xs={4} >
          {renderSocialIcons()}
        </Grid>

      </Grid>
    </Box>
  );
}
