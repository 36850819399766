import React from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CharacterTyping from '../../media/character_typing.json'
import Lottie from "lottie-react";


export default function RowAndColumnSpacing() {
    return (
        <>
            <Box className='introBox' sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 'auto'
            }}>
                <Grid container rowSpacing={3} >
                    <Grid classname='lottieGridHolder' xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                        <Lottie className='lottieGuyTyping' animationData={CharacterTyping} loop={true} play/>
                    </Grid>
                    <Grid xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                        <Typography className='introText' sx={{ flexGrow: 1, textAlign: 'left', margin: 0, boxShadow: 0 }}>
                            Hello World 👋 My name is Dan.
                            I’m a software engineer passionate about learning new skills, seeing the world, gaming with my friends and music in general.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <svg
                viewBox="0 0 3041 123"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="intro_curve_divider"
            >
                <path
                    d="M3041 123V76.001C1993.5-22.5 892.497-28 .997 76.001V123H3041z"
                    fill="currentColor"
                ></path>
            </svg>
        </>
    );
}