import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function RowAndColumnSpacing() {
    return (
        <>
            <Box className='contactMeBox' sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
                // margin: 'auto',
                // width: '100%'
            }}>

                <Grid container direction="column">
                    <Grid >
                        <Typography 
                        className='contactMeText' 
                        variant='h1'
                        sx={{textAlign: 'left',}}
                        >
                            hey there!
                        </Typography>
                    </Grid>
                    <Grid >
                        <Typography 
                        className='contactMeText' 
                        variant='h4'
                        sx={{ textAlign: 'left',}}>
                            I’m always up for a chat!
                        </Typography>
                    </Grid>
                    <Grid >
                        <Typography 
                        className='contactMeText' 
                        variant='h4'
                        sx={{ textAlign: 'left', }}>
                            I can be reached at hi@danboyle.dev 
                        </Typography>
                    </Grid>


                </Grid>
                {/* <div className='contactMeBlobContainer'>
                    <svg
                        viewBox="0 0 2240 1563"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="contactMeBackgroundBlob">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.363 646.889c-63.026-151.192 63.5-397.525 316.505-361.878 253.006 35.647 307.915 61.646 387.127 53.405 258.104-26.849 364.285-258.32 614.245-316.058 236.42-54.61 504.96-24.576 785.77 308.322 198.21 234.971 117.2 549.587-8.26 721.46-167.57 229.58-299.93 119.12-556.78 280.12-160.06 100.33-358.52 305.26-692.923 202.01-248.463-76.72-330.096-369.6-424.788-512.02C303.931 758.432 97.761 842.137 16.363 646.889z"
                            fill="currentColor">
                        </path>
                    </svg>
                </div> */}

            </Box>
        </>
    );
}