
import "../App.css";
import Header from "../components/header/Header";
import ContactMe from "../components/contactMe/ContactMe";
import Footer from "../components/footer/Footer";


export default function Contact() {

  return (
    <div className="ContactMePageContainer">
      <svg
        viewBox="0 0 1978 1682"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="topLeftBackgroundBlob">
        <path d="M513.003 1081c-316.8 102.4-449.333 446.67-512 600V1H1977c-72 301.333-142.4 664.8-312 764-212 124-755.997 188-1151.997 316z" fill="currentColor">
        </path>
      </svg>
      <Header green={true}/>
      <ContactMe />
      <Footer />
    </div>
  );
}