import "../App.css";
import Header from "../components/header/Header";
import Intro from "../components/intro/Intro";
import AboutMe from "../components/aboutMe/AboutMe";
import Footer from "../components/footer/Footer";

function Root() {
  return (
    <div className="App">
      <div className="fFRNGy" />
      <Header />
      <Intro />
      <AboutMe />
      <Footer />
    </div>
  );
}

export default Root;
